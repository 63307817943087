import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { compileString } from "sass";

const initialState = {
  isAuthenticated: false,
  idToken: null,
  refreshToken: null,
  adminAccess: false,
  isLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticate: (state, action) => {
      // const payload = action.payload;
      //
    },
    deAuthenticate: (state) => {
      state.isAuthenticated = false;
      state.idToken = null;
      state.refreshToken = null;
    },
    setAuthenticationStatus: (state, action) => {
      //
      if (!action.payload === false) {
        state.isAuthenticated = true;
        state.idToken = action.payload.data.idToken;
        state.refreshToken = action.payload.data.refreshToken;
        state.adminAccess = action.payload.data.is_superuser;
      } else {
        state.isAuthenticated = action.payload;
        //
      }
    },
    setIsLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  authenticate,
  deAuthenticate,
  setAuthenticationStatus,
  setIsLoading,
} = authSlice.actions;
export default authSlice.reducer;
