import axios from "axios";
import { returnErrors } from "../../store/reducers/error";

export const baseUrl = "https://api.equityedge.co.uk/";
// export const baseUrl = "http://3.8.154.118/";

const signupRequest = async (formdata) => {
  const response = await axios.post(`${baseUrl}auth/register/`, formdata);
  return response;
};

//User
const getUserDetailsnew = async (idToken, data) => {
  //removed clg
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${baseUrl}auth/admin/update-user-details/?email=${data}`,
      config
    );
    if (response.status === 201 || response.status === 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};
const updateUserDetailsnew = async (idToken, data) => {
  //removed clg
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.patch(
      `${baseUrl}auth/admin/update-user-details/`,
      data,
      config
    );
    if (response.status === 201 || response.status === 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

const getUserDetails = async (idToken) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const output = await axios
    .get(`${baseUrl}user/profile/`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
  return output;
};

// UserAddress
const getUserAddress = async (idToken) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const output = await axios
    .get(`${baseUrl}user/billing/address/`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
  return output;
};

// Post
const addUserAddress = async (idToken, data) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const output = await axios
    .post(`${baseUrl}user/billing/address/`, data, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
  return output;
};

// Patch
const updateUserAddress = async (idToken, data) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const output = await axios
    .patch(`${baseUrl}user/billing/address/`, data, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
  return output;
};

const updateUserDetailsRequest = async ({ formData, idToken }) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  let output;
  await axios
    .patch(`${baseUrl}user/profile/`, formData, config)
    .then((res) => {
      output = res.data;
      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });
  return output;
};

const loadUser = async (idToken) => {
  let config = {
    headers: {
      Authorization: "Bearer " + idToken,
    },
  };
  let output;
  await axios
    .get(`${baseUrl}auth/user/`, config)
    .then((res) => {
      output = res;
      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });
};

//funding

const getFundingDetails = async (idToken) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  let output;
  await axios
    .get(`${baseUrl}account/funding-evaluation/`, config)
    .then((res) => {
      output = res;
      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};

//support
const getSupportTableDetails = async (
  idToken,
  pageNumber,
  type,
  severity,
  status,
  search
) => {
  const params = {
    page: pageNumber,
    page_size: 50,
  };
  if (type) {
    params.type = typeof type === "string" ? type : type.value;
  }
  if (severity) {
    params.severity = severity.value;
  }
  if (status) {
    params.status = status.value;
  }
  if (search) {
    params.user = search;
    params.page = 1;
  }
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    params: params,
  };
  let output;
  await axios
    .get(`${baseUrl}support/admin/case/`, config)
    .then((res) => {
      output = res.data;

      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};
const getSupportTableDetailsNew = async (
  idToken,
  pageNumber,
  type,
  severity,
  status,
  search,
  platform
) => {
  const params = {
    page: pageNumber,
    page_size: 50,
  };
  if (type) {
    params.account_type = typeof type === "string" ? type : type.value;
  }
  if (severity) {
    params.severity = severity.value;
  }
  if (status) {
    params.status = status.value;
  }
  if (search) {
    params.search = search;
    params.page = 1;
  }
  if (platform) {
    params.platform = platform;
  }
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    params: params,
  };
  let output;
  await axios
    .get(`${baseUrl}account/admin/contracts/`, config)
    .then((res) => {
      output = res.data;

      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};

const updateSupportDetails = async (idToken, id, formData) => {
  const apiUrl = `${baseUrl}support/admin/case/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  let output;
  await axios
    .patch(apiUrl, formData, config)
    .then((res) => {
      if (res?.response?.stauts > 399) {
        output = res;
        return output;
      }
      output = res;
      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });
  return output;
};

const deleteSupportDetails = async (idToken, id) => {
  const apiUrl = `${baseUrl}support/admin/case/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.delete(apiUrl, config);

    return response;
  } catch (error) {
    return error;
  }
};

//Certificates
const getCertificatesDetails = async (
  idToken,
  pageNumber,
  phase,
  search,
  newSearch
) => {
  const params = {
    page: pageNumber,
    page_size: 21,
  };
  if (phase) {
    params.phase = phase.value;
  }
  if (search !== null) {
    params.search = search;
    params.page = 1;
  } else {
    params.search = newSearch;
  }

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    params: params,
  };

  try {
    const response = await axios.get(
      `${baseUrl}certificate/admin/all/`,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
};

// TraderList

const getAllTradersRequest = async (
  idToken,
  searchValue,
  pageNumber,
  phase,
  active,
  competition,
  tab,
  dispatch,
  platform
) => {
  try {
    const params = {
      page: pageNumber,
      page_size: 20,
    };
    if (searchValue !== null) {
      params.search = searchValue;
      // params.page = 1;
    }
    if (active === true) {
      params.active = "True";
    }
    if (active === false) {
      params.active = "False";
    }
    if (phase) {
      params.phase = phase.value;
    }
    // if (competition === true) {
    //   params.competition = "True";
    // }
    // if (competition === false) {
    //   params.competition = "False";
    // }
    if (tab !== null) {
      params.type = tab;
    }
    if (platform) {
      params.platform = platform;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      params: params,
    };

    const response = await axios.get(
      `${baseUrl}account/admin/accounts-list/`,
      config
    );

    if (response?.status < 399) return response.data;
    if (response.response?.status > 399) {
      dispatch(returnErrors("something went wrong", 400));
      return {
        count: 0,
        results: [],
      };
    }
  } catch (error) {
    // dispatch(returnErrors("something went wrong", 400));
    return {
      count: 0,
      results: [],
    };
  }
};
const getSearchTradersRequest = async (idToken, competition, search) => {
  try {
    const params = {
      search: search,
    };
    if (competition === true) {
      params.competition = "True";
    }

    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      params: params,
    };

    const response = await axios.get(
      `${baseUrl}account/admin/accounts-list/`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

//affiliate
const traderAffiliateRefList = async (idToken, id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      // params: {
      //   affiliate_code_id: id,
      // },
    };
    const response = await axios.get(
      `${baseUrl}affiliates/admin/referred-list/${id}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error in getting trader ref list", error);
    throw error;
  }
};
const getAffiliateDetails = async (idToken) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  let output;
  await axios
    .get(`${baseUrl}affiliates/list/`, config)
    .then((res) => {
      output = res.data;

      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};

const getAffiliateList = async (idToken) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  let output;
  await axios
    .get(`${baseUrl}affiliates/admin/affiliate-list/`, config)
    .then((res) => {
      output = res;

      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};
const sendCredential = async (idToken, login_id) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  let output;
  await axios
    .post(`${baseUrl}account/admin/email-credentials/`, login_id, config)
    .then((res) => {
      output = res;
      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};
const getAffiliateListV2 = async (idToken, search, currentPage) => {
  console.log("search", search);
  const params = {
    page: currentPage,
    page_size: 21,
  };
  if (search) {
    params.search = search;
    params.page = currentPage;
  }
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
    params: params,
  };

  const url = `${baseUrl}affiliates/admin/affiliate-list/v2/`;
  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    return error;
  }
};

const postAffiliateDetails = async (idToken, data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${baseUrl}affiliates/create-link/`,
      data,
      config
    );
    if (response.status === 201 || response.status === 200) {
      return response.data;
    } else {
      console.error(
        "POST request for affiliate data failed:",
        response.status,
        response.data
      );
      throw new Error("POST request failed");
    }
  } catch (error) {
    console.error("Error making POST request for affiliate data:", error);
    throw error;
  }
};

// User List
const getUserList = async (
  idToken,
  search,
  active,
  authT,
  currentPage,
  searchValue
) => {
  const params = {
    page: currentPage,
    page_size: 21,
  };
  if (active) {
    params.active = "True";
  } else {
    params.active = "False";
  }
  if (active === null) {
    delete params.active;
  }
  if (search) {
    params.search = search;
    params.page = searchValue ? 1 : currentPage;
  }
  if (authT) {
    params.auth_type = authT;
    // params.page = 1;
  }

  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
    params: params,
  };

  const url = `${baseUrl}auth/admin/user-list/`;

  let output;
  await axios
    .get(url, config)
    .then((res) => {
      output = res;

      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};

//user login
const postAdminLogin = async (idToken, value) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };

  // console.log(value);
  // return
  //create a form data
  const formData = new FormData();
  formData.append("email", value.user);
  let output;
  await axios
    .post(`${baseUrl}auth/admin/custom-token/`, formData, config)
    .then((res) => {
      output = res;
      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};

//Payout Apis
const getPayoutListRequest = async (
  idToken,
  search,
  method,
  payoutType,
  currentPage
) => {
  const params = {
    page: currentPage,
    page_size: 20,
  };

  if (method) {
    params.status = method;
    // params.page = 1 || currentPage;
  }
  if (search) {
    params.search = search;
    params.page = currentPage;
  }
  if (payoutType) {
    params.payout_type = payoutType;
  }

  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
    params: params,
  };

  const url = `${baseUrl}user/admin/payout/`;

  let output;
  await axios
    .get(url, config)
    .then((res) => {
      output = res;

      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};

const generatePayoutRequest = async (idToken, data) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  try {
    const response = await axios.put(
      `${baseUrl}user/admin/payout/${data.id}/`,
      data?.data,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
};
const generatePayoutRequestNew = async (idToken, data) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  try {
    const response = await axios.post(
      `${baseUrl}user/admin/payout/`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Payment List
const getPaymentList = async (
  idToken,
  search,
  status,
  plan,
  currentPage,
  searchValue,
  platform
) => {
  const params = {
    page: currentPage,
    page_size: 21,
  };

  if (status) {
    params.payment_status = status;
  }
  if (search) {
    params.search = search;
    params.page = search === searchValue ? currentPage : 1;
  }
  if (plan) {
    params.plan = plan;
  }
  if (platform) {
    params.platform = platform;
  }

  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
    params: params,
  };

  const url = `${baseUrl}payment/admin/transaction-history/`;

  let output;
  await axios
    .get(url, config)
    .then((res) => {
      output = res;

      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};
// affiliate codes list
const getAffiliateCodelist = async (idToken, search, currentPage) => {
  const params = {
    page: currentPage,
    page_size: 21,
  };

  // if (status) {
  //   params.payment_status = status;
  // }
  if (search) {
    params.search = search;
    params.page = currentPage;
  }
  // if (plan) {
  //   params.plan = plan;
  // }

  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
    params: params,
  };

  const url = `${baseUrl}affiliates/admin/affiliate-code-list/v2/`;

  let output;
  await axios
    .get(url, config)
    .then((res) => {
      output = res;

      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};

const getCodelistV2 = async (idToken, email) => {
  const params = {
    page_size: 1000,
  };

  if (email) {
    params.affiliate = email;
  }

  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
    params: params,
  };
  let output;
  await axios
    .get(`${baseUrl}affiliates/admin/affiliate-code-list/v2/`, config)
    .then((res) => {
      output = res;

      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};

// Payment ListOLD
const getPaymentListOLD = async (idToken, status) => {
  const params = {};

  if (status) {
    params.payment_status = status;
  }
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
    params: params,
  };
  let output;
  await axios
    .get(`${baseUrl}payment/admin/list/`, config)
    .then((res) => {
      output = res;

      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};

// for user status
const changeUserStatus = async (idToken, email, data) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  let output;
  await axios
    .post(
      `${baseUrl}auth/admin/change-user-status/?email=${email}`,
      data,
      config
    )
    .then((res) => {
      output = res;

      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};
// for reset password
const resetPassword = async (idToken, data) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  let output;
  await axios
    .post(`${baseUrl}auth/admin/reset-password/`, data, config)
    .then((res) => {
      output = res;

      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};
// for delete user
const deleteUser = async (idToken, email) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  let output;
  await axios
    .delete(`${baseUrl}auth/admin/delete-user/?email=${email}`, config)
    .then((res) => {
      output = res;

      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};
// for risk management
const getRiskManagement = async (idToken, data = {}) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  try {
    const response = await axios.post(
      `${baseUrl}account/admin/risk-management-overview/v2/ `,
      data,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
};

//coupon

const getCouponDetails = async ({ idToken, inputText }) => {
  const params = {};
  if (inputText) {
    params.coupon = inputText;
  }

  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
    params: params,
  };
  let output;
  await axios
    .get(`${baseUrl}payment/admin/coupon/`, config)
    .then((res) => {
      output = res.data;

      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};

const postCouponDetails = async (idToken, data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${baseUrl}payment/admin/coupon/`,
      data,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
};

const deleteCouponDetails = async (idToken, couponCode) => {
  const apiUrl = `${baseUrl}payment/admin/coupon/?coupon=${couponCode}`;

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.delete(apiUrl, config);
  } catch (error) {
    console.error("An error occurred while deleting the coupon:", error);
  }
};

const updateCouponDetails = async (idToken, data, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.patch(
      `${baseUrl}payment/admin/coupon/?coupon=${id}`,
      data,
      config
    );

    if (response.status === 201 || response.status === 200) {
      return response.data;
    } else {
      console.error(
        "PATCH request for coupon data failed:",
        response.status,
        response.data
      );
      throw new Error("PATCH request failed");
    }
  } catch (error) {
    console.error("Error making PATCH request for coupon data:", error);
    throw error;
  }
};

//adv

const getAdvDetails = async (idToken) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  let output;
  await axios
    .get(`${baseUrl}marketing/admin/marketing/`, config)
    .then((res) => {
      output = res.data;

      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};

const postAdvDetails = async (idToken, data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };

    const response = await axios.post(
      `${baseUrl}marketing/admin/marketing/`,
      data,
      config
    );

    if (response.status === 201 || response.status === 200) {
      return response.data;
    } else {
      console.error(
        "POST request for adv data failed:",
        response.status,
        response.data
      );
      throw new Error("POST request failed");
    }
  } catch (error) {
    console.error("Error making POST request for adv data:", error);
    throw error;
  }
};

const deleteAdvDetails = async (idToken, Id) => {
  const apiUrl = `${baseUrl}marketing/admin/marketing/?advertisement_id=${Id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.delete(apiUrl, config);

    if (response.status === 204) {
    } else {
      console.error(`Failed to delete adv with code ${Id}.`);
    }
  } catch (error) {
    console.error("An error occurred while deleting the adv:", error);
  }
};

const updateAdvDetails = async (idToken, data, id) => {
  const apiUrl = `${baseUrl}marketing/admin/marketing/?advertisement_id=${id}`;

  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  let output;
  await axios
    .patch(apiUrl, data, config)
    .then((res) => {
      output = res.data;
      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });
  return output;
};

//competition

const getCompDetails = async (idToken) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  let output;
  await axios
    .get(`${baseUrl}account/admin/competitions/`, config)
    .then((res) => {
      output = res;

      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};

const postCompDetails = async (idToken, data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${baseUrl}account/admin/competitions/`,
      data,
      config
    );
    if (response.status === 201 || response.status === 200) {
      return response;
    } else {
      return response;
      // throw new Error("POST request failed");
    }
  } catch (error) {
    console.error("Error making POST request for comp data:", error);
    // throw error;
    return error;
  }
};

const deleteCompDetails = async (idToken, id) => {
  const apiUrl = `${baseUrl}account/admin/competitions/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.delete(apiUrl, config);

    if (response.status === 201 || response.status === 204) {
      return response;
    }
  } catch (error) {
    return error;
    // throw error;
  }
};

const updateCompDetails = async (idToken, data, id) => {
  const apiUrl = `${baseUrl}account/admin/competitions/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };
  let output;
  await axios
    .patch(apiUrl, data, config)
    .then((res) => {
      output = res.data;
      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });
  return output;
};

//plans

const getPlansTableDetails = async (idToken) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  let output;
  try {
    await axios.get(`${baseUrl}account/admin/plans/`, config).then((res) => {
      output = res.data;

      return output;
    });
  } catch (error) {
    output = error;
    return output;
  }

  return output;
};

const updatePlanTableDetails = async (idToken, data, id) => {
  const apiUrl = `${baseUrl}account/admin/plans/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };
  let output;
  await axios
    .patch(apiUrl, data, config)
    .then((res) => {
      output = res.data;
      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });
  return output;
};

const getPlansDetails = async (idToken) => {
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    const response = await axios.get(
      `${baseUrl}account/admin/challenges/`,
      config
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getChallengesDetails = async (idToken) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  let output;
  await axios
    .get(`${baseUrl}account/funding-evaluation/`, config)
    .then((res) => {
      output = res;

      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};

const postPlansDetails = async ({ idToken, data }) => {
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    const response = await axios.post(
      `${baseUrl}account/admin/challenges/`,
      data,
      config
    );
    if (response.status === 201 || response.status === 200) {
      return response;
    } else {
      console.error(
        "POST request for plans data failed:",
        response.status,
        response.data
      );
    }
  } catch (error) {
    return error;
    // throw error;
  }
};

const updatePlansDetails = async ({ idToken, data, id }) => {
  const apiUrl = `${baseUrl}account/admin/challenges/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };
  let output;

  // return
  await axios
    .patch(apiUrl, data, config)
    .then((res) => {
      output = res;
      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });
  return output;
};

const deletePlansDetails = async ({ idToken, id }) => {
  const apiUrl = `${baseUrl}account/admin/challenges/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };
  let output;
  await axios
    .delete(apiUrl, config)
    .then((res) => {
      output = res;
      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });
  return output;
};

const deletePlansTableDetails = async (idToken, id) => {
  const apiUrl = `${baseUrl}account/admin/plans/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axios.delete(apiUrl, config);

    if (response.status === 201 || response.status === 204) {
      return true;
    }
  } catch (error) {
    return error;
    // throw error;
  }
};

const postPlansTableDetails = async (idToken, data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${baseUrl}account/admin/plans/`,
      data,
      config
    );
    if (response.status === 201 || response.status === 200) {
      return response;
    } else {
      console.error(
        "POST request for plans data failed:",
        response.status,
        response.data
      );
    }
  } catch (error) {
    return error;
    // throw error;
  }
};
// import user to admin apis
const getFundingEvaluationPlansRequest = async (idToken) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    const response = await axios.get(
      `${baseUrl}account/funding-evaluation/`,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const getPaymentHistoryRequest = async (data) => {
  let config = {
    headers: {
      Authorization: `Bearer ${data.idToken}`,
    },
    // params:{email:email}
  };
  const output = await axios
    .get(`${baseUrl}payment/admin/payment-history/?email=${data.email}`, config)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      throw error;
    });
  return output;
};
const getCompetitionListRequest = async (idToken) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    const response = await axios.get(
      `${baseUrl}account/competition-list/`,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const enterCompetitionRequest = async (idToken, body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    const response = await axios.post(
      `${baseUrl}account/participate-competition/`,
      body,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
};
// Leaderboard
const getLeaderboardDetailsReq = async (idToken, id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    const response = await axios.get(
      `${baseUrl}user/competition/${id}/`,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
};

const getUserAccountListRequest = async (idToken) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const output = await axios
    .get(`${baseUrl}account/list/`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      throw error;
    });
  return output;
};

const getAccountMetricsRequest = async ({ idToken, loginId, dispatch }) => {
  try {
    if (idToken && loginId) {
      let config = {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      };

      const response = await axios.get(
        `${baseUrl}account/metrics/${loginId}`,
        config
      );
      if (response.status >= 400) {
        throw new Error(response);
      }

      return response;
    }
  } catch (error) {
    throw error;
  }
};
const getBalanceChartRequest = async ({ idToken, loginId }) => {
  try {
    if (idToken && loginId) {
      let config = {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      };
      const response = await axios.get(
        `${baseUrl}account/balance-equity/${loginId}`,
        config
      );

      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

// const getPayoutListRequest = async (idToken) => {
//   let config = {
//     headers: { Authorization: `Bearer ${idToken}` },
//   };
//   const output = await axios
//     .get(`${baseUrl}user/payout/list/`, config)
//     .then((res) => {
//       return res.data;
//     })
//     .catch(function (error) {
//       throw error;
//     });
//   return output;
// };
const getTraderJournalRequest = async (idToken) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const output = await axios
    .get(`${baseUrl}account/trade-journal/`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      throw error;
    });
  return output;
};

//awards
const createAwardRequest = async (idToken, data, dispatch) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const response = await axios.post(
    `${baseUrl}account/admin/award/`,
    data,
    config
  );

  if (response.status < 399) {
    return response;
  } else {
    dispatch(returnErrors("faild to create award", 400));
  }
};
const updateAwardRequest = async (idToken, data, dispatch) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const response = await axios.patch(
    `${baseUrl}account/admin/award/`,
    data,
    config
  );

  if (response.status < 399) {
    return response;
  } else {
    dispatch(returnErrors("faild to create award", 400));
  }
};
// Upgrade account

const upgradeUserAccountReq = async (idToken, data) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const response = await axios.post(
    `${baseUrl}account/admin/create-account/`,
    data,
    config
  );
  return response;
};
const upgradeFundedUserAccountReq = async (idToken, data) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const response = await axios.post(
    `${baseUrl}account/admin/funded-account-create/`,
    data,
    config
  );
  return response;
};
const generateContractRequest = async (idToken, id, data) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const response = await axios.post(
    `${baseUrl}account/admin/generate-contract/${id}`,
    data,
    config
  );
  return response;
};
const updateUserAccountReq = async (idToken, data) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const response = await axios.patch(
    `${baseUrl}account/admin/update-account/`,
    data,
    config
  );
  return response;
};

const getOverviewReq = async (idToken, phase) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  const response = await axios.get(
    `${baseUrl}account/admin/account-overview/?phase=${phase}`,
    config
  );
  return response;
};

// Create trading account apis
const UserSearchReq = async (idToken, search) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${baseUrl}auth/admin/search-user/?search=${search}`,
      config
    );
    if (response.status === 201 || response.status === 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};
const postCouponDetailsnew = async (idToken, data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${baseUrl}payment/admin/v2/coupon`,
      data,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
};
const GetCertificatesADMIN = async (idToken) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${baseUrl}certificate/list/`, config);
    if (response.status === 201 || response.status === 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};
const CreateCertificate = async (idToken, data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${baseUrl}certificate/admin/create/`,
      data,
      config
    );
    if (response.status === 201 || response.status === 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

const EligibleCertificateAccount = async (idToken, email) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${baseUrl}certificate/admin/elible-certificates-acccounts/?email=${email}`,
      config
    );
    if (response.status === 201 || response.status === 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

const CreateTradingAccountReq = async (idToken, data) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };
  try {
    const response = await axios.post(
      `${baseUrl}account/admin/create-account/`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
};
// IP logs
const ipLogsReq = async (idToken, search, blocked, currentPage) => {
  const params = {
    page: currentPage,
    page_size: 20,
  };
  if (blocked) {
    params.blocked = blocked;
  }
  if (search) {
    params.search = search;
    params.page = currentPage;
  }
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      params: params,
    };
    const response = await axios.get(`${baseUrl}auth/admin/ip-logs/`, config);
    return response;
  } catch (error) {
    return error;
  }
};

// commission for payout

const getCommission = async (idToken, selectedEmail) => {
  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
    params: {
      email: selectedEmail,
    },
  };

  const url = `${baseUrl}user/admin/eligible-commission/`;

  let output;
  await axios
    .get(url, config)
    .then((res) => {
      output = res;
      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};

// User Profile Update

const updateUserDetailsNew = async (idToken, data) => {
  //removed clg
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.patch(
      `${baseUrl}auth/admin/update-user-details/`,
      data,
      config
    );
    if (response.status === 201 || response.status === 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

// Password Change api
const changeUserPassword = async (idToken, payload) => {
  //removed clg
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${baseUrl}auth/admin/change-user-password/`,
      payload,
      config
    );
    if (response.status === 201 || response.status === 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    throw error;
  }
};

const getUserProfileData = async (idToken, email) => {
  const params = {
    email: email,
  };

  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
    params: params,
  };

  const url = `${baseUrl}auth/admin/update-user-details/`;

  let output;
  await axios
    .get(url, config)
    .then((res) => {
      output = res;
      return output;
    })
    .catch(function (error) {
      output = error;
      return output;
    });

  return output;
};

export {
  updateUserDetailsRequest,
  loadUser,
  signupRequest,
  getUserAddress,
  getAffiliateCodelist,
  addUserAddress,
  updateUserAddress,
  getUserDetails,
  getFundingDetails,
  getSupportTableDetails,
  getCertificatesDetails,
  updateSupportDetails,
  deleteSupportDetails,
  getAllTradersRequest,
  getSearchTradersRequest,
  getAffiliateDetails,
  postAffiliateDetails,
  getCouponDetails,
  postCouponDetails,
  updateCouponDetails,
  deleteCouponDetails,
  deleteUser,
  getAdvDetails,
  postAdvDetails,
  deleteAdvDetails,
  resetPassword,
  changeUserStatus,
  updateAdvDetails,
  getCompDetails,
  postCompDetails,
  deleteCompDetails,
  updateCompDetails,
  getPlansDetails,
  postPlansDetails,
  deletePlansDetails,
  updatePlansDetails,
  getPlansTableDetails,
  updatePlanTableDetails,
  deletePlansTableDetails,
  getRiskManagement,
  postPlansTableDetails,
  getFundingEvaluationPlansRequest,
  getPaymentHistoryRequest,
  getCompetitionListRequest,
  getLeaderboardDetailsReq,
  getUserAccountListRequest,
  getAccountMetricsRequest,
  getBalanceChartRequest,
  getPayoutListRequest,
  getTraderJournalRequest,
  postCouponDetailsnew,
  traderAffiliateRefList,
  getChallengesDetails,
  createAwardRequest,
  updateUserDetailsnew,
  getPaymentList,
  generateContractRequest,
  getAffiliateList,
  upgradeUserAccountReq,
  upgradeFundedUserAccountReq,
  getOverviewReq,
  updateAwardRequest,
  updateUserAccountReq,
  getPaymentListOLD,
  getUserList,
  getAffiliateListV2,
  generatePayoutRequest,
  UserSearchReq,
  CreateTradingAccountReq,
  ipLogsReq,
  EligibleCertificateAccount,
  GetCertificatesADMIN,
  CreateCertificate,
  sendCredential,
  getSupportTableDetailsNew,
  generatePayoutRequestNew,
  postAdminLogin,
  getCodelistV2,
  getUserDetailsnew,
  getUserProfileData,
  getCommission,
  updateUserDetailsNew,
  changeUserPassword,
};
